"use client";

import { useSession } from "next-auth/react";
import React from "react";

export function HeaderBannerRoot({ children, className }: { children: React.ReactNode, className: string }) {
  const { data: session } = useSession();

  if (!session?.user) return null;

  if (session.user.subscription_plan !== 0) return null;

  return (
    <div className={`sticky top-0 flex justify-center items-center z-[3] ${className}`}>{children}</div>
  )
}