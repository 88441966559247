'use client'


import { BannerDiscountModal } from "@/components/Banner/BannerDiscountModal";
import { BannerDiscountTimer } from "@/components/Banner/BannerDiscountTimer";
import { BannerPreferenceStore } from "@/lib/store/banner-preferences";

import Link from "next/link";
import { MdOutlineContentCopy } from "react-icons/md";
import { toast } from "sonner";

import { useSession } from "next-auth/react";
import { Button } from "../ui/button";

export function HeaderBannerBody() {
  const bannerViewed = BannerPreferenceStore((state) => state.viewed)

  const { data: session } = useSession();

  if (!session?.user) return null;

  const { approvedAt } = session.user;

  const moreThriteenDays = new Date(approvedAt) < new Date(new Date().setMonth(new Date().getMonth() - 1))

  const percentage = moreThriteenDays ? 60 : 30

  const couponCode = `SUGAR${percentage}`

  return (
    <>
      {
        bannerViewed ?
          <div className="w-full top-20 flex items-center justify-center bg-fs-red-500 p-2">
            <div className="sm:hidden flex gap-1 items-center w-full justify-center">
              <span>
                Cupom
              </span>

              <div className="flex items-center gap-1">

                <span style={{ fontWeight: "bold" }} className="text-xl">
                  {percentage}%
                </span>

                <span>
                  off
                </span>
              </div>

              <div className="flex items-center gap-1">
                <span className="font-mono w-fit bg-primary text-primary-foreground px-2 py-1 rounded">
                  {couponCode}
                </span>

                <MdOutlineContentCopy
                  className="cursor-pointer"
                  onClick={() => {
                    toast.success("Cupom copiado!");

                    navigator.clipboard.writeText(couponCode)
                  }}
                />
              </div>

              <div className="max-[480px]:hidden flex gap-1">
                <span>
                  Válido até:
                </span>

                <span style={{ fontWeight: "bold" }}>
                  <BannerDiscountTimer />
                </span>
              </div>

              <Button
                asChild
                className="bg-fs-red-800 max-[480px]:ml-6"
                variant='fs-red'
              >
                <Link href="/signature-plan">
                  Assinar
                </Link>
              </Button>
            </div>

            <p className="text-sm md:text-base gap-2 items-center hidden sm:flex">
              Use o cupom

              <div className="flex gap-2 items-center">
                <Link href={'/signature-plan'}>
                  <span className="font-mono w-fit bg-primary text-primary-foreground px-2 py-1 rounded">
                    {couponCode}
                  </span>
                </Link>

                <MdOutlineContentCopy
                  className="cursor-pointer"
                  onClick={() => {
                    toast.success("Cupom copiado!");

                    navigator.clipboard.writeText(couponCode)
                  }}
                />
              </div>

              <span className="flex gap-2 items-center">
                para obter

                <span style={{ fontWeight: "bold" }} className="underline">
                  {percentage}%
                </span>

                de desconto! Expira em
              </span>

              <span className="font-bold">
                <BannerDiscountTimer />
              </span>

              <Button
                asChild
                className="bg-fs-red-800"
                variant='fs-red'
              >
                <Link href="/signature-plan">
                  Assinar!
                </Link>
              </Button>
            </p>
          </div>
          :
          <BannerDiscountModal
            couponCode={couponCode}
            discountAmount={percentage}
          />
      }
    </>
  )
}