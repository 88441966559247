'use client';

import React, { ReactNode } from 'react'

import { SessionProvider } from 'next-auth/react';
import { FinanceProvider } from '../app/contexts/finance';

import ControlSessionProvider from './ControlSessionProvider';

export default function NextAuthProvider({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      <ControlSessionProvider>
        <FinanceProvider>
          {children}
        </FinanceProvider>
      </ControlSessionProvider>
    </SessionProvider>
  )
}