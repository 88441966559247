'use client'

import { useState, useEffect } from 'react'

export function BannerDiscountTimer() {
  const [isVisible, setIsVisible] = useState(true)
  const [timeLeft, setTimeLeft] = useState(3600)

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsVisible(false)
      return
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  if (!isVisible) return null

  const hours = Math.floor(timeLeft / 3600)
  const minutes = Math.floor((timeLeft % 3600) / 60)
  const seconds = timeLeft % 60

  const formatTime = (value: number) => value.toString().padStart(2, '0')

  return (
    <span className="font-bold">
    {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
  </span>
  )
}

