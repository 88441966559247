'use client'

import { useState, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog"

import Link from 'next/link'
import { BannerPreferenceStore } from '@/lib/store/banner-preferences'
import { BannerDiscountTimer } from '@/components/Banner/BannerDiscountTimer'

import Image from 'next/image'
import { MdOutlineContentCopy } from 'react-icons/md'
import { toast } from 'sonner'
interface DiscountModalProps {
  couponCode: string
  discountAmount: number
}

export function BannerDiscountModal({ couponCode, discountAmount }: DiscountModalProps) {
  const bannerChangeViewed = BannerPreferenceStore((state) => state.changeViewed)
  const [isOpen, setIsOpen] = useState(true)
  const [timeLeft, setTimeLeft] = useState(3600)

  useEffect(() => {
    if (!isOpen) bannerChangeViewed(true)
  }, [isOpen])

  useEffect(() => {
    const timer = setTimeout(() => setIsOpen(true), 2000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsOpen(false)
      return
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[400px]">
        <div className="flex flex-col items-center gap-4 py-4">
          <Image alt="find-sugar-logo" width={150} height={150} src="/assets/Logo.svg" />

          <div className="items-end flex gap-2">
            <span
              style={{ fontWeight: "bold" }}
              className="text-fs-red-400 text-5xl"
            >
              {discountAmount}%
            </span>
          </div>

          <p className="text-center flex flex-col items-center text-lg">
            <div className="flex gap-2 items-center">
              Use o cupom

              <span className="font-mono w-fit text-2xl bg-primary text-primary-foreground px-2 py-1 rounded">
                {couponCode}
              </span>

              <MdOutlineContentCopy
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(couponCode);
                  toast.success("Cupom copiado!");
                }}
              />
            </div>

            <p className="flex gap-2 items-center">
              para obter

              <span>
                {discountAmount}%
              </span>

              de desconto!
            </p>
          </p>

          <p className="text-center text-lg gap-2 flex">
            Expira em

            <span className="font-bold">
              <BannerDiscountTimer />
            </span>
          </p>
        </div>

        <DialogFooter className="sm:justify-center">
          <DialogClose asChild>
            <Button
              asChild
              variant='fs-red'
              className="w-full"
            >
              <Link href="/signature-plan">
                Assinar!
              </Link>
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

