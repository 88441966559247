import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  viewed: boolean
}

export type Actions = {
  changeViewed: (viewd: boolean) => void
}

export const BannerPreferenceStore = create<State & Actions>()(
  persist(
    (set) => ({
      viewed: false,
      changeViewed: (viewed: boolean) => set({ viewed })
    }),
    { name: 'banner-preferences', skipHydration: false }
  )
)