'use client'
import { Button, ButtonProps } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { signOut } from 'next-auth/react'
import { SlLogout } from 'react-icons/sl'

export function ButtonSignOut(props: ButtonProps) {
  const onClick = async () => await signOut({ callbackUrl: '/login' })
  return (
    <Button
      className={cn('flex items-center justify-start gap-2 h-full w-full text-white z-50', props.className)}
      variant='ghost'
      {...props}
      onClick={onClick}
    >
      <SlLogout />
      <span>Sair</span>
    </Button>
  )
}